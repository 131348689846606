import React from "react"
import { graphql, PageProps } from "gatsby"
import styled from "styled-components"
// import { Link } from "gatsby"
// import Image from "../components/image"

import Layout from "../components/layout"
import BlogCard from "../components/blog-card"
import SEO from "../components/seo"
import Image from "../components/image"

const BlogCardContainer = styled.div`
  margin: 50px auto 0px auto;
  max-width: 960px;
  min-height: 100vh;
  padding: 0 1.0875rem 1.45rem;
`

const PageTitle = styled.h1`
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 100;
  font-size: 82px;
  color: #fff;
  font-style: italic;
  transform: translate(-50%, 0);
  letter-spacing: 2px;

  @media only screen and (max-width: 700px) {
    font-size: 32px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0px auto 0px auto;
  border-radius: 3px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  overflow: hidden;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
`

const ComingSoonAlert = styled.h1`
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface DataProps {
  allMarkdownRemark: {
    totalCount: number
    edges: NodeObj[]
  }
}

interface NodeObj {
  node: {
    id: string
    timeToRead: number
    frontmatter: {
      title: string
      author: string
      date: string
      description: string
    }
    fields: {
      slug: string
    }
  }
}

const IndexPage: React.FC<PageProps<DataProps>> = props => {
  const results = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO title="Home" />

      <ImageContainer>
        <PageTitle>Blog</PageTitle>
        <Image />
      </ImageContainer>

      <BlogCardContainer>
        {results?.map(({ node }, idx) => {
          return (
            <BlogCard
              key={node.id}
              {...node}
              isNotLast={idx !== results.length - 1}
            />
          )
        })}
      </BlogCardContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          timeToRead
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            author
            description
          }
          fields {
            slug
          }
          excerpt(pruneLength: 240)
        }
      }
    }
  }
`

export default IndexPage
