import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { AiOutlineClockCircle } from "react-icons/ai"

const CardWrapper = styled.article`
  position: relative;
  display: block;
  margin: 0 auto 45px 0;
  text-decoration: none;
  /* min-height: 300px; */
`

const Attribution = styled(Link)`
  text-decoration: none;
  .author {
    color: #666;
    font-size: 1.3rem;
    font-weight: bold;
    text-decoration: underline;
    padding-right: 3px;
  }

  span.wrote {
    font-size: 1.2rem;
    text-decoration: none !important;
    font-weight: normal;
    color: #999;
    font-style: italic;
  }
`

const Title = styled.h2`
  text-align: left;
  font-size: 32px;
  color: #222;
  margin: 15px 0px 20px 0px;
  max-width: 750px;
  text-transform: capitalize;
  @media only screen and (max-width: 700px) {
    font-size: 20px;
  }
`

const DateAndDescription = styled.div`
  margin-bottom: 25px;
  font-size: 20px;
  max-width: 800px;

  .date {
    text-transform: uppercase;
    font-size: 18px;
    color: #666;
  }

  .description {
    color: #333;
  }
  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
`

const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const ReadMore = styled(Link)`
  padding: 0px;
  margin: 0px 0px 50px 0px;
  border: none;
  background: transparent;
  color: #006fc6;
  font-weight: bold;
  font-size: 20px;
  border-bottom: 1px solid #006fc6;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #0a4b7d;
    border-color: #006fc6;
  }

  @media only screen and (max-width: 700px) {
    font-size: 16px;
  }
`

const ReadTime = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  svg {
    color: red;
    font-size: 24px;
    margin-right: 5px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 16px;
    svg {
      font-size: 18px;
    }
  }
`

const Divider = styled.div`
  position: absolute;
  bottom: 0px;
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
  /* right: 0px; */
  left: 0px;
  width: 300px;
  margin: 0 auto;
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
`

interface DataProps {
  timeToRead: number
  isNotLast: boolean
  frontmatter: {
    title: string
    author: string
    date: string
    description: string
  }
  fields: {
    slug: string
  }
}

const BlogCard = (props: DataProps): JSX.Element => {
  return (
    <CardWrapper>
      <Attribution to="/steve-fischer">
        <span className="author">{props.frontmatter.author}</span>{" "}
        <span className="wrote">wrote</span>
      </Attribution>
      <Title>{props.frontmatter.title}</Title>
      <DateAndDescription>
        <span className="date">{props.frontmatter.date} &#8212; </span>
        <span className="description">{props.frontmatter.description}</span>
      </DateAndDescription>
      <ReadMoreWrapper>
        <ReadMore to={props.fields.slug}>Read More...</ReadMore>
        <ReadTime>
          <AiOutlineClockCircle />
          {props.timeToRead} min read
        </ReadTime>
      </ReadMoreWrapper>
      {props.isNotLast && <Divider />}
    </CardWrapper>
  )
}
// &gt;&gt;
export default BlogCard
